<template>
    <div>
        <el-table border v-loading="loading" :data="dataList" >
            <el-table-column label="政策编号" prop="policyNo" min-width="80" :show-overflow-tooltip="true"/>
            <el-table-column label="政策名称" prop="policyName" min-width="160" :show-overflow-tooltip="true"/>
            <el-table-column label="政策说明" prop="policyRemark" min-width="200" :show-overflow-tooltip="true"/>
            <el-table-column label="近期修改时间" prop="updateTime" min-width="140" :show-overflow-tooltip="true"/>
            <el-table-column label="操作人" prop="updateBy" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text"
                               @click="handleDetail(scope.row)"  v-permission="'POLICY_INFO_QUERY'">详情</el-button>
                    <el-button size="mini" type="text"
                               @click="handleUpdate(scope.row)"
                               v-permission="'POLICY_INFO_UPDATE'">修改</el-button>
                </template>
            </el-table-column>
        </el-table>

        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {PolicyApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false
            }
        },
        created() {
            this.getList()
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await PolicyApi.policyInfo.list(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            handleUpdate(row) {
                this.$router.push({
                    name:'ratePolicyUpdate',
                    query:{
                        policyNo:row.policyNo,
                        type:'update'
                    }
                })
            },
            handleDetail(row) {
                this.$router.push({
                    name:'ratePolicyDetail',
                    query:{
                        policyNo:row.policyNo
                    }
                })
            },
        }
    };
</script>

<style scoped>

</style>
